import { motion } from "framer-motion";

export default function DefaultAnimated({ children }) {
  return (
    <motion.div
      className="contained"
      initial={{ opacity: 0 }}
      whileInView={{
        opacity: 1,
        transition: {
          ease: "easeInOut",
        },
      }}
      viewport={{ once: false, amount: 0.7 }}
    >
      <div className="card">{children}</div>
    </motion.div>
  );
}
