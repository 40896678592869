import BaseParallax from "../components/Sections/BaseParallax";
import { motion } from "framer-motion";
import DefaultAnimated from "../components/Sections/DefaultAnimated";
import FloatingAnimation from "../components/FloatingAnimation";

export default function Home() {
  return (
    <main>
      <BaseParallax id="about-me">
        <FloatingAnimation
          count={8}
          posX={[5, 80, 45, 80, 10, 40, 50, 85]}
          posY={[15, 10, 5, 75, 70, 85, 25, 60]}
          icons={[
            <img src="/images/c1.svg" alt="clloud" height={"50px"} />,
            <img src="/images/c2.svg" alt="clloud" height={"50px"} />,
            <img src="/images/c3.svg" alt="clloud" height={"50px"} />,
            <img src="/images/c1.svg" alt="clloud" height={"50px"} />,
            <img src="/images/c2.svg" alt="clloud" height={"50px"} />,
            <img src="/images/c2.svg" alt="clloud" height={"50px"} />,
            <img src="/images/c3.svg" alt="clloud" height={"50px"} />,
            <img src="/images/c3.svg" alt="clloud" height={"50px"} />,
          ]}
          entry="idle"
          animation="none"
        />
        <motion.div
          className="animated-obj d-none d-xl-flex"
          initial={{
            opacity: 0,
          }}
          whileInView={{
            opacity: 1,
            x: "12%",
            transition: { ease: "easeInOut" },
          }}
        >
          <img
            src="/images/profile.png"
            alt="Fullstack Web Developer"
            className="profile"
          />
        </motion.div>
        <motion.div
          className="animated-obj d-flex d-xl-none"
          whileInView={{
            x: "5%",
            transition: { ease: "easeInOut" },
          }}
        >
          <img
            src="/images/profile.png"
            alt="Fullstack Web Developer"
            className="profile"
          />
        </motion.div>
        <DefaultAnimated>
          <h1 className="labelled">About Me</h1>
          <p>
            I am an ESFJ, play a role as{" "}
            <strong className="super">fullstack developer</strong> since 2015. I
            am a believer of God, gifted with very talented wife and a wonderful
            boy.
          </p>
        </DefaultAnimated>
      </BaseParallax>
      <BaseParallax id="what-am-i-doing">
        <FloatingAnimation
          count={6}
          posX={[5, 70, 45, 80, 10, 40]}
          posY={[5, 10, 5, 75, 70, 85]}
          icons={[
            <i className="icons">🧑🏻‍🚀</i>,
            <i className="icons">🎮</i>,
            <i className="icons">♟️</i>,
            <i className="icons">🧑🏻‍🚀</i>,
            <i className="icons">🧑🏼‍🔬</i>,
            <i className="icons">♟️</i>,
          ]}
          entry={"slideUp"}
          animation="rotate"
        />
        <DefaultAnimated>
          <h1 className="labelled">Current occupancy</h1>
          <p>
            Founded{" "}
            <a
              href="https://fortidigitalstudio.com"
              target="_blank"
              rel="noreferrer"
            >
              FORTI Digital Studio
            </a>{" "}
            in 2017 which provide website / app develpoment, UI & UX, Wordpress
            Developer.
          </p>
          <p>
            I also co-founded{" "}
            <a
              href="https://refineaccounting.com"
              target="_blank"
              rel="noreferrer"
            >
              Refine Accounting
            </a>
            , it's a SaaS company which provide Accounting System.
          </p>
        </DefaultAnimated>
      </BaseParallax>
      <BaseParallax id="passion">
        <FloatingAnimation
          count={5}
          posX={[15, 80, 25, 50, 85]}
          posY={[10, 75, 75, 10, 40]}
          icons={[
            <i className="icons">🚀</i>,
            <i className="icons">🐈</i>,
            <i className="icons">✨</i>,
            <i className="icons">🎸</i>,
            <i className="icons">🚀</i>,
          ]}
          entry="floating"
          animation="rotate"
        />
        <DefaultAnimated>
          <h1 className="labelled">What I am passionate about</h1>
          <p>
            I am very passionate in{" "}
            <strong className="super">finance industry</strong> and have a deep
            interest in{" "}
            <strong className="super">IoT, Machine Learning and AI.</strong> In
            the future would like to position myself deeper in finance industry.
          </p>
        </DefaultAnimated>
      </BaseParallax>
      <BaseParallax id="skills">
        <div className="d-none d-xl-block">
          <FloatingAnimation
            count={5}
            posX={[21, 10, 17, 10, 21]}
            posY={[25, 30, 40, 50, 57]}
            icons={[
              <img src="/images/ts.png" alt="clloud" height={"75px"} />,
              <img src="/images/js.png" alt="clloud" height={"75px"} />,
              <img src="/images/bs.png" alt="clloud" height={"75px"} />,
              <img src="/images/php.png" alt="clloud" height={"75px"} />,
              <img src="/images/figma.png" alt="clloud" height={"75px"} />,
            ]}
            entry="bounceLeft"
            animation="none"
          />
          <FloatingAnimation
            count={5}
            posX={[72, 80, 75, 80, 73]}
            posY={[25, 30, 40, 50, 57]}
            icons={[
              <img src="/images/wp.png" alt="clloud" height={"75px"} />,
              <img src="/images/git.png" alt="clloud" height={"75px"} />,
              <img src="/images/laravel.png" alt="clloud" height={"75px"} />,
              <img src="/images/mysql.png" alt="clloud" height={"75px"} />,
              <img src="/images/sheets.png" alt="clloud" height={"75px"} />,
            ]}
            entry="bounceRight"
            animation="none"
          />
        </div>
        <div className="d-block d-xl-none">
          <FloatingAnimation
            count={5}
            posX={[12, 25, 42, 58, 78]}
            posY={[22, 12, 20, 12, 18]}
            icons={[
              <img src="/images/ts.png" alt="clloud" height={"75px"} />,
              <img src="/images/js.png" alt="clloud" height={"75px"} />,
              <img src="/images/bs.png" alt="clloud" height={"75px"} />,
              <img src="/images/php.png" alt="clloud" height={"75px"} />,
              <img src="/images/figma.png" alt="clloud" height={"75px"} />,
            ]}
            entry="bounceUp"
            animation="none"
          />
          <FloatingAnimation
            count={5}
            posX={[10, 25, 42, 58, 78]}
            posY={[72, 82, 71, 69, 72]}
            icons={[
              <img src="/images/wp.png" alt="clloud" height={"75px"} />,
              <img src="/images/git.png" alt="clloud" height={"75px"} />,
              <img src="/images/laravel.png" alt="clloud" height={"75px"} />,
              <img src="/images/mysql.png" alt="clloud" height={"75px"} />,
              <img src="/images/sheets.png" alt="clloud" height={"75px"} />,
            ]}
            entry="bounceBottom"
            animation="none"
          />
        </div>
        <DefaultAnimated>
          <h1 className="labelled">Skills</h1>
          <ul>
            <li>
              <span className="title">Coding: </span>
              <span className="description">
                Javascript, Typescript, ReactJS, NodeJS, Webpack, Git, Laravel,
                Bootstrap.
              </span>
            </li>
            <li>
              <span className="title">Management: </span>
              <span className="description">
                Finance, Accounting, Trello, Excel (Sheets).
              </span>
            </li>
            <li>
              <span className="title">Design: </span>
              <span className="description">
                Illustrator, Figma, Canva, Photoshop.
              </span>
            </li>
            <li>
              <span className="title">Music: </span>
              <span className="description">Guitar.</span>
            </li>
          </ul>
        </DefaultAnimated>
      </BaseParallax>
      <BaseParallax className="stories">
        <DefaultAnimated>
          <h1 className="labelled">Stories</h1>
          <ul>
            <li>
              <span className="title">From July 2022 - Until Now: </span>
              <span className="description">
                Part time lecturer at{" "}
                <a
                  href="https://informatika.petra.ac.id"
                  target="_blank"
                  rel="noreferrer"
                >
                  Petra Christian University, Faculty of Technology and Industry
                </a>
                . Teaching Algorithm & Programming, Web Technology.
              </span>
            </li>
            <li>
              <span className="title">From Jan 2017 - Until Now: </span>
              <span className="description">
                A CEO, A Full Stack Developer, A Marketing Manager at FORTI
                Digital Studio and Refine Accounting.
              </span>
            </li>
            <li>
              <span className="title">From Jan 2015 - Dec 2016: </span>
              <span className="description">
                Junior at{" "}
                <a
                  href="https://smart-it.co.id"
                  target="_blank"
                  rel="noreferrer"
                >
                  Smart IT
                </a>{" "}
                and Project Manager at{" "}
                <a
                  href="https://storemantap.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Storemantap
                </a>
                . Involved build ECommerce web apps & android apps.
              </span>
            </li>
          </ul>
        </DefaultAnimated>
      </BaseParallax>
      <BaseParallax className="stories">
        <DefaultAnimated>
          <h1 className="labelled">Stories</h1>
          <ul>
            <li>
              <span className="title">From July 2011 - Jan 2015: </span>
              <span className="description">
                Studied at{" "}
                <a
                  href="https://informatika.petra.ac.id"
                  target="_blank"
                  rel="noreferrer"
                >
                  Petra Christian University
                </a>
                . I get my B. Comp major in Computer Science with GPA 3,56.
              </span>
            </li>
          </ul>
        </DefaultAnimated>
      </BaseParallax>
      <BaseParallax id="socials">
        <DefaultAnimated>
          <h1 className="labelled">Follow me on</h1>
          <ul>
            <li>
              <a
                href="https://instagram.com/singgihmardianto"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://tiktok.com/@singgihmardianto"
                target="_blank"
                rel="noreferrer"
              >
                TikTok
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/singgih-soephomo/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://github.com/singgihmardianto/"
                target="_blank"
                rel="noreferrer"
              >
                Github
              </a>
            </li>
          </ul>
        </DefaultAnimated>
      </BaseParallax>
    </main>
  );
}
