import { motion } from "framer-motion";

export default function FloatingAnimation({
  count,
  posX,
  posY,
  icons,
  entry = "idle",
  animation = "none",
  initial = null,
}) {
  const random = (max, min) => {
    return Math.round(Math.random()) * (max ?? 5) + (min ?? 0);
  };

  const entry_animations = {
    idle: (i) => {
      return {
        x: [`${posX[i]}%`, `${posX[i] + random(10, -7)}%`, `${posX[i]}%`],
        transition: {
          duration: random(10, 8),
          repeat: Infinity,
        },
      };
    },
    floating: (i) => {
      return {
        x: [`${posX[i]}%`, `${posX[i] + random(10, -7)}%`, `${posX[i]}%`],
        y: [`${posY[i]}%`, `${posY[i] + random(20, -10)}%`, `${posY[i]}%`],
        transition: {
          duration: 15,
          repeat: Infinity,
        },
      };
    },
    slideUp: (i) => {
      return {
        opacity: 1,
        y: `${posY[i]}%`,
        transition: {
          duration: 1,
          type: "spring",
          bounce: 0.5,
          damping: random(20, 10),
        },
      };
    },
    bounceLeft: (i) => {
      return {
        opacity: 1,
        x: `${posX[i]}%`,
        y: `${posY[i]}%`,
        transition: {
          duration: 0.8,
          type: "spring",
          bounce: 0.5,
          damping: random(20, 10),
        },
      };
    },
    bounceRight: (i) => {
      return {
        opacity: 1,
        x: `${posX[i]}%`,
        y: `${posY[i]}%`,
        transition: {
          duration: 0.8,
          type: "spring",
          bounce: 0.4,
          damping: random(8, 5),
        },
      };
    },
    bounceUp: (i) => {
      return {
        opacity: 1,
        x: `${posX[i]}%`,
        y: `${posY[i]}%`,
        transition: {
          duration: 0.8,
          type: "spring",
          bounce: 0.4,
          damping: random(8, 5),
        },
      };
    },
    bounceBottom: (i) => {
      return {
        opacity: 1,
        x: `${posX[i]}%`,
        y: `${posY[i]}%`,
        transition: {
          duration: 0.8,
          type: "spring",
          bounce: 0.4,
          damping: random(8, 5),
        },
      };
    },
    none: {},
  };

  const initOptions = {
    idle: (i) => {
      return {
        opacity: 1,
        x: `${posX[i]}%`,
        y: `${posY[i]}%`,
      };
    },
    floating: (i) => {
      return {
        opacity: 1,
        x: `${posX[i]}%`,
        y: `${posY[i]}%`,
      };
    },
    slideUp: (i) => {
      return {
        opacity: 0,
        x: `${posX[i]}%`,
        y: `99%`,
      };
    },
    bounceLeft: (i) => {
      return {
        opacity: 1,
        x: `50%`,
        y: `${posY[i]}%`,
        transition: {
          duration: 1,
        },
      };
    },
    bounceRight: (i) => {
      return {
        opacity: 1,
        x: `50%`,
        y: `${posY[i]}%`,
        transition: {
          duration: 1,
        },
      };
    },
    bounceUp: (i) => {
      return {
        opacity: 1,
        x: `${posX[i]}%`,
        y: `50%`,
      };
    },
    bounceBottom: (i) => {
      return {
        opacity: 1,
        x: `${posX[i]}%`,
        y: `50%`,
      };
    },
    none: {},
  };

  const animations = {
    rotate: (i) => {
      return {
        rotate: [0, 360],
        transition: {
          duration: random(7, 5),
          repeat: Infinity,
          ease: "linear",
        },
      };
    },
    none: {},
  };

  let elements = Array.apply(null, Array(count)).map((val, i) => {
    const _init = initOptions[entry](i);
    return (
      <motion.div
        key={i}
        className="floating-animation"
        custom={i}
        initial={_init}
        whileInView={entry}
        variants={entry_animations}
        viewport={{ once: false }}
      >
        <motion.div
          custom={i}
          whileInView={["rotate"].includes(animation) ? animation : ""}
          variants={animations}
        >
          {icons && icons[i]}
        </motion.div>
      </motion.div>
    );
  });

  return <>{elements.map((elm) => elm)}</>;
}
