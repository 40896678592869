import Dropdown from "react-bootstrap/Dropdown";

export default function Header() {
  return (
    <header className="scrolled">
      <div className="logo"></div>
      <div className="nav">
        <Dropdown>
          <Dropdown.Toggle id="dropdown-basic">Menu</Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item href="#about-me">About Me</Dropdown.Item>
            <Dropdown.Item href="#what-am-i-doing">My Occupancy</Dropdown.Item>
            <Dropdown.Item href="#skills">Skills</Dropdown.Item>
            <Dropdown.Item href="#stories">Stories</Dropdown.Item>
            <Dropdown.Item href="#socials">Follow Me</Dropdown.Item>
            <Dropdown.Item href="/journals">Journals</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
}
